import createLucideIcon from '../createLucideIcon';

const Archive = createLucideIcon('Archive', [
  [
    'rect',
    { x: '2', y: '4', width: '20', height: '5', rx: '2', key: '1h2p0l' },
  ],
  ['path', { d: 'M4 9v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9', key: 'shkvi4' }],
  ['path', { d: 'M10 13h4', key: 'ytezjc' }],
]);

export default Archive;
