import createLucideIcon from '../createLucideIcon';

const Building = createLucideIcon('Building', [
  [
    'rect',
    {
      x: '4',
      y: '2',
      width: '16',
      height: '20',
      rx: '2',
      ry: '2',
      key: '152kg8',
    },
  ],
  ['path', { d: 'M9 22v-4h6v4', key: 'r93iot' }],
  ['path', { d: 'M8 6h.01', key: '1dz90k' }],
  ['path', { d: 'M16 6h.01', key: '1x0f13' }],
  ['path', { d: 'M12 6h.01', key: '1vi96p' }],
  ['path', { d: 'M12 10h.01', key: '1nrarc' }],
  ['path', { d: 'M12 14h.01', key: '1etili' }],
  ['path', { d: 'M16 10h.01', key: '1m94wz' }],
  ['path', { d: 'M16 14h.01', key: '1gbofw' }],
  ['path', { d: 'M8 10h.01', key: '19clt8' }],
  ['path', { d: 'M8 14h.01', key: '6423bh' }],
]);

export default Building;
