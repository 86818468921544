import createLucideIcon from '../createLucideIcon';

const Video = createLucideIcon('Video', [
  ['path', { d: 'm22 8-6 4 6 4V8Z', key: '50v9me' }],
  [
    'rect',
    {
      x: '2',
      y: '6',
      width: '14',
      height: '12',
      rx: '2',
      ry: '2',
      key: '14il7g',
    },
  ],
]);

export default Video;
