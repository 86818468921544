import createLucideIcon from '../createLucideIcon';

const Delete = createLucideIcon('Delete', [
  [
    'path',
    { d: 'M20 5H9l-7 7 7 7h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Z', key: '1oy587' },
  ],
  ['line', { x1: '18', y1: '9', x2: '12', y2: '15', key: '1328vg' }],
  ['line', { x1: '12', y1: '9', x2: '18', y2: '15', key: '6xbiik' }],
]);

export default Delete;
