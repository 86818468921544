import createLucideIcon from '../createLucideIcon';

const CreditCard = createLucideIcon('CreditCard', [
  [
    'rect',
    { x: '2', y: '5', width: '20', height: '14', rx: '2', key: 'qneu4z' },
  ],
  ['line', { x1: '2', y1: '10', x2: '22', y2: '10', key: '1ytoly' }],
]);

export default CreditCard;
